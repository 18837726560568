.shop-item {
  position: relative;
  display: flex;
  align-items: center;
  background: #eeeeee;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 10px;

  &.disabled {
    cursor: not-allowed;
    &::before {
      z-index: 100;
      position: absolute;
      top: 0;
      left: 0;
      content: attr(data-reason);
      color: white;
      font-weight: 700;
      opacity: 1.5;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0,0,0,.5);
      border-radius: 5px;
      font-size: 2rem;
      text-transform: uppercase;
    }
  }
  .product {
    .preview {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
      width: 100px;
      margin-right: 10px;

      img, video {
        margin: 0 auto;
        max-height: calc(100%);
        max-width: calc(100%);
      }
    }
  }
  .info {
    .name {
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 1.8rem;
      display: flex;
      flex-wrap: nowrap;

      > div {
        color: #616161;
      }
      small {
        font-size: .8rem;
        font-weight: 300;
        font-style: italic;
      }
    }
    .subname {
      margin-top: .5rem;
      font-size: .8rem;
      line-height: 1rem;
      color: #B4B4B4;
    }
  }
  .quantity {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: .5rem;
    .total {
      font-size: .8rem;
      font-weight: 700;
      color: #878787;
    }
    input, .claimed {
      font-size: 0.74rem;
      background-color: #FFFFFF;
      border: none;
      padding: 10px;
      width: 100px;
      text-align: center;
      font-weight: 900;
      outline: none;
      color: #9e9e9e;
      border-radius: 5px;
      &::placeholder {
        color: #E1E1E1;
      }
    }
    .claimed {
      text-transform: uppercase;
      padding: 8px 10px;
      font-size: .8rem;
      font-weight: bold;
    }
    button {
        margin: 0;
    }
  }
}

@media only screen and (max-width: 650px) {
  .shop-item {
    display: block;
    text-align: center;
    flex-wrap: wrap;

    .product {
      margin-bottom: 1rem;

      .preview {
        display: block;
        justify-content: initial;
        align-items: initial;
        height: initial;
        margin: 0;
        text-align: center;
        width: 100%;

        img, video {
          max-width: 80px;
        }
      }
    }
    .info {
      margin-bottom: 1rem;

      .name {
        > div {
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;
        }
      }
      .subname {
        text-align: center;
      }
    }
    .quantity {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}