#StorePage {
  .logged-out {
    padding-top: 2rem;
    text-align: center;

    .section-heading {
      font-size: .85rem;
      font-weight: 600;
      line-height: 2;
    }

    button {
      margin-top: 1rem;
    }
  }

  .payment-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 15px;

    .payment-option {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }

      flex-grow: 1;

      button {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 42px;

        img {
          max-height: 20px;
          margin: -1em 0;
        }

        opacity: .5;

        &.active {
          opacity: 1;
        }
      }
    }
  }

  .shop-items {
    display: flex;
    flex-flow: column;
    height: calc(100% - (61px + 91px));

    &.noheight {
      height: initial;
    }

    overflow-y: auto;
  }
}

@media only screen and (max-width: 650px) {
  #StorePage {
    .payment-options {
      flex-flow: column;
      margin-bottom: 5px;

      .payment-option {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }
}