main#RedeemVirl, main#CollectVirl, main#CompleteVirl {
  .logged-out {
    padding-top: 2rem;
    text-align: center;

    button {
      margin-top: 1rem;
    }
  }

  .virl-redemption {
    h2 {
      font-weight: 800;
    }

    .section-heading {
      font-size: .85rem;
      font-weight: 600;
      line-height: 2;
    }

    .shipping-address {
      padding: .5rem 0 0 0;
      font-size: .8rem;

      .shipping-change {
        padding-top: .5rem;

        .link {
          color: #6865D8;
          cursor: pointer;
        }
      }
    }

    .shipping-methods {
      padding: .5rem 0 0 0;
      font-size: .8rem;

      .field {
        margin-bottom: 1rem;
        cursor: pointer;

        label {
          font-size: .8rem;
        }

        .shipping-cost {
          margin-left: 1.5rem;
          font-weight: 600;
        }
      }
    }

    .dropdown {
      font-size: .78571429em;
    }

    .react-tel-input {
      font-size: .78571429em;
      margin-bottom: 1rem;

      .form-control {
        font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
        margin: 0;
        outline: 0;
        -webkit-appearance: none;
        tap-highlight-color: rgba(255, 255, 255, 0);
        font-size: 1em;
        line-height: 1.21428571em;
        padding: .67857143em 1em .67857143em 48px;
        color: rgba(0, 0, 0, .87);
        border: 1px solid rgba(34, 36, 38, .15);
        box-shadow: 0 0 0 0 transparent inset;
        transition: color .1s ease, border-color .1s ease;

        &:hover {
          border-color: rgba(34, 36, 38, .15);
        }

        &:focus {
          color: rgba(0, 0, 0, .95);
          border-color: #85b7d9;
          border-radius: .28571429rem;
          background: #fff;
          box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.35) inset;
        }
      }
    }

    .virl-controls {
      margin-top: -.5rem;
    }
  }

  .virl-preview {
    padding-top: 2rem;

    img, video {
      margin: auto;
    }

    .virl-details {
      margin-top: 1rem;

      .virl-name {
        font-weight: 600;
      }

      .virl-info {
        font-size: .8rem;
      }

      .virl-restriction {
        font-size: .9rem;
        margin-top: .5rem;
      }
    }
  }
}

main#CollectVirl {
  .virl-redemption {
    .shipping-methods {

      .field {
        cursor: auto;

        span {
          font-weight: normal;
          font-size: .8rem;
        }

        .shipping-cost {
          margin-left: 0;
        }
      }
    }
  }

  .virl-deposit {
    border-top: 1px solid #E7E7E7;
    border-bottom: 1px solid #E7E7E7;
    margin: 1.2rem 0;
    padding: 1rem 0;
    text-align: center;
    font-size: .9rem;
    font-weight: 600;
    color: #9e9e9e;
  }
}