::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar-thumb {
    background: #18171B;
    border-radius: 15px;
}
::-webkit-scrollbar-track {
    background: #fff;
}

* {
    font-family: 'Open Sans', sans-serif;
}

html {
    background-color: #ffffff;
    margin: 0;
    font-size: 16px;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}

body {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
}

//h1,h2,h3,h4,h5,h6, p {
//    margin: 0;
//}

html, body, #root {
    height: 100%;
}

a, span.link {
    text-decoration: none;
    color: inherit;

    &:hover {
        color: #6865D8;
    }
}

.ui.primary.button, .ui.primary.buttons .button {
    background-color: #6865D8;

    &:active, &.active {
        background-color: #6865D8;
    }

    &:focus {
        background-color: #8B88FF;
    }

    &:hover {
        background-color: #8B88FF;
    }
}

.content {
    main {
        hr {
            border-bottom: 1px solid #E7E7E7;
            border-top: none;
        }
        .checkout {
            background: #eeeeee;
            padding: 15px;
            border-radius: 5px;
            margin-bottom: 10px;
        }
        .confirm {
            padding: 10px 0;
            border-top: 1px solid #ccc;
        }
        .order-status {
            margin-bottom: 50px;
            h2 {
               border: none; 
               &.success {
                   color: #4052f1;
               }
            }
            p {
                font-weight: 600;
                color: #9e9e9e;
                a {
                    color: #4052f1;
                    text-decoration: none;
                }
            }
        }

        fieldset {
            border-color: #e6e6e6;
            border-style: solid;
        }
        form {
            #dob {
                select, input, button {
                    display: inline;
                    width: auto;
                    margin-right: .2rem;
                }
            }

            #tos-group {
                .terms-list {
                    margin: 0.5rem 0 0 0;
                    padding-left: 1.2rem;

                    li {
                        cursor: pointer;
                        line-height: 2;
                    }
                }
            }

            /* Chrome, Safari, Edge, Opera */
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            input[type=number] {
                -moz-appearance: textfield;
            }


            input[type=checkbox] {
                margin: 0 4px 0 0;
                vertical-align: middle;
            }

            select:invalid {
                color: #b8b8b8;
                font-weight: 600;
            }
        }

        .warning-restriction {
            padding: 10px 25px;
            background-color: #5c6aec;
            margin-top: 15px;
            border-radius: 10px;
            font-weight: 700;
            color: white;
        }

        button {
            &[disabled] {
                pointer-events: all !important;
                cursor: not-allowed !important;
                opacity: .4;
            }
            &:hover:not([disabled]) {
                transition: background-color .5s, color .5s;
                background-color: #6865D8;
                color: #ffffff;
            }
        }
    }
    aside {
        display: flex;
        flex-flow: column;
        padding: 25px;
        width: 450px;
        border-left: 1px solid #E7E7E7;
        animation: fadeInRight 2s ;
        background-color: white;
        overflow-y: auto;
        h2 {
            text-transform: uppercase;
            font-size: 32px;
            line-height: 32px;
            color: #4e4e4e;
            text-transform: uppercase;
            .close {
                display: none;
            }
        }
        h4 {
            font-size: 13px;
            line-height: 13px;
            padding-bottom: 5px;
            color: #bdbdbd;
            border-bottom: 1px solid #E7E7E7;
            margin-bottom: 10px;
        }
        .preview {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #eeeeee;
            padding: 15px;
            border-radius: 5px;
            margin-bottom: 10px;
            img, video {
                max-height: 180px;
            }
        }
        .description {
            h3 {
                color: #878787;
            }
            p {
                color: #BDBDBD;
                font-size: .9rem;
                font-weight: 600;
            }
        }
        .bottom {
            margin-top: auto;
            .price {
                display: flex;
                align-items: center;
                color: #878787;
                font-size: .8rem;
                .right {
                    margin-left: auto;
                }
            }
            .form {
                display: flex;
                width: 100%;
                margin-top: 5px;
                padding-top: 15px;
                border-top: 1px solid #E7E7E7;
                input {
                    font-size: 0.74rem;
                    background-color: #F5F5F5;
                    border: none;
                    padding: 10px;
                    width: 75px;
                    text-align: center;
                    font-weight: 900;
                    outline: none;
                    color: #9e9e9e;
                    margin-right: 10px;
                    border-radius: 5px;
                    &::placeholder {
                        color: #CACACA;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    
}

@media only screen and (max-width: 650px) {
    .container {
        max-width: calc(100%);
        padding: 0;
        main {
            .payment-options {
                flex-flow: column;
                .payment-option {
                    margin-right: 0;
                }
            }
        }
        aside {
            position: fixed;
            top: 0;
            left: 0;
            height: calc(100% - 50px);
            width: calc(100% - 50px);
            h2 {
                display: flex;
                align-items: center;
                font-size: 24px;
                .close {
                    margin-left: auto;
                    display: block;
                    cursor: pointer;
                    padding: 0 10px;
                }
            }
        }
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}