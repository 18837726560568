header {
  margin-bottom: 80px;

  .sticky {
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    height: 80px;

    .navbar {
      width: 100%;
      margin: 0 auto;
      padding: 1rem 3rem;

      .navbox {
        font-weight: 600;
        align-items: center;
        justify-content: space-between;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;

        .logo {
          flex: 0 0 auto;
          width: auto;
          max-width: 100%;

          img {
            width: 130px;
          }
        }

        .navright {
          display: block;
          flex: 0 0 auto;
          width: auto;
          max-width: 100%;

          .username {
            font-weight: 600;
          }
        }
      }
    }
  }
}