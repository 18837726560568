footer {
  background-color: #fff;
  border-top: 1px solid #dee2e6;
  margin-top: 80px;

  .footbar {
    padding: 3rem 0;

    .footbox {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      div {
        position: relative;
        padding-right: 15px;
        padding-left: 15px;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
      }

      .logo {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        img {
          width: 110px;
        }
      }

      .copyright {

      }

      .links {
        font-size: 18px;

        a {
          color: black;
          transition: .5s;
          font-weight: 600;

          &:hover {
            color: #8B88FF;
          }
        }
      }
    }
  }
}